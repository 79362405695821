import { Modal, ModalProps } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

import { CloseIcon } from '../../../Icons'
import './style.less'

export interface IColorModalProps extends Omit<ModalProps, 'visible'> {
    isModalVisible?: boolean
    setIsModalVisible?: (val: boolean) => void
    header: JSX.Element | string
    body?: JSX.Element | string
    headerColor?: 'gray' | 'red' | 'green' | 'buying' | 'selling'
}

export const ColorModal: FC<IColorModalProps> = ({
    isModalVisible,
    setIsModalVisible,
    header,
    body,
    width = 600,
    headerColor = 'gray',
    closable = false,
    onCancel,
    children,
    ...props
}) => {
    const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsModalVisible?.(false)
        onCancel?.(e)
    }

    return (
        <Modal
            {...props}
            visible={isModalVisible}
            width={width}
            onCancel={handleCancel}
            footer={null}
            className="color-dfa-modal"
            bodyStyle={{ padding: 0 }}
            centered
            closable={closable}
            closeIcon={<CloseIcon className="color-dfa-modal__close-icon" />}
        >
            <div className={clsx('color-dfa-modal__wrapper', headerColor)}>
                <div className="color-dfa-modal__header">{header}</div>
                <div className="color-dfa-modal__body">{body || children}</div>
            </div>
        </Modal>
    )
}
